// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.my-atropos {
    width: 320px;
    height: 160px;

}
*{
    color:"#000";
}
#dashFooter{
    background-color: unset !important;
}`, "",{"version":3,"sources":["webpack://./src/app/dashboard/Dashboard.css"],"names":[],"mappings":"AAAA;IACI,YAAY;IACZ,aAAa;;AAEjB;AACA;IACI,YAAY;AAChB;AACA;IACI,kCAAkC;AACtC","sourcesContent":[".my-atropos {\n    width: 320px;\n    height: 160px;\n\n}\n*{\n    color:\"#000\";\n}\n#dashFooter{\n    background-color: unset !important;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
